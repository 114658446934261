.amenities {
  width: 100%;
  padding: 50px 20px;
  border-radius: 15px;
}

.amenities-container {
  width: 100%;
  padding: 1rem;
  height: auto;
}

.amenities-title {
  padding-bottom: 2.5rem;
  text-align: center;
}

.heading-text {
  color: #fff;
  font-size: 2.5rem;
  text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.5);
}

.amenities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 25px;
  max-width: 1300px;
  margin: 0 auto;
}

.amenities-card {
  overflow: hidden;
  border-radius: 10px;
  text-align: center;
}

.amenities-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.amenities-content {
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.amenities-title-text {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.amenities-description {
  font-size: 16px;
  color: #fff;
  margin-bottom: 12px;
}

@media screen and (max-width: 768px) {
  .amenities {
    padding: 20px 0;
  }

  .amenities-container {
    padding: 0;
  }

  .amenities-title {
    padding-bottom: 1rem;
  }

  .heading-text {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .amenities-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    width: 90%;
  }

  .amenities-content {
    padding: 0.5rem;
  }

  .amenities-title-text {
    font-size: 15px;
  }

  .amenities-description {
    font-size: 10px;
  }
}
