.our-story {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  background: linear-gradient(135deg, #0b2b3e 0%, #0493a5 100%);
  border-radius: 15px;
}

/* Main Card */
.card {
  width: 100%;
  max-width: 1200px;
  background: var(--headerBg3);
  backdrop-filter: blur(10px);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: auto;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.2);
}

/* Title */
.card-title {
  padding-bottom: 2rem;
}

.heading-text {
  color: var(--headerBg);
  font-size: 2.5rem;
  font-weight: 500;
}

/* Layout: Text & Image */
.card-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
}

/* Text Section */
.card-text {
  width: 60%;
  padding: 1rem;
  text-align: justify;
}

.description {
  font-size: 18px;
  font-weight: 400;
  color: var(--headerBg);
  margin: 20px;
}

/* Image Section */
.card-image-div {
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-image,
.card-image-II {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.card-image:hover,
.card-image-II:hover {
  transform: scale(1.05);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .our-story {
    padding: 10px 5px;
  }

  .card {
    padding: 1rem;
  }

  .card-title {
    padding-bottom: 1rem;
  }

  .card-body {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .card-text {
    width: 100%;
    padding: 0rem;
  }

  .description {
    font-size: 12px;
  }

  .card-image-div {
    width: 100%;
  }

  .heading-text {
    font-size: 1.375rem;
  }
}
