.iframeContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  border: 0.5px solid #ccc;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.iframe {
  width: 100%;
  height: 550px; /* Adjust height */
  border: none;
  border-radius: 10px;
  background-color: white;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headingWrapper {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headingText {
  color: var(--headerBg2);
  font-weight: 400;
  font-size: 1.7rem;
}

.btnClose {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #ccc;
}

.closeIcon {
  width: 15px;
  margin-bottom: 1rem;
}

.logo {
  width: 70px;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .iframeContainer {
    padding: 10px 0 0 0;
  }

  .headingText {
    font-size: 1.2rem;
  }

  .headerContainer {
    padding: 0 10px;
  }

  .iframe {
    height: 500px;
  }
}
