@import url(../../styles/sizes.css);
@import url(../../styles/colors.css);

.navbar {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list {
  display: flex;
  list-style: none !important;
}

.navLinks {
  display: flex;
  gap: 20px;
  list-style: none;
}

.navLinks a {
  color: var(--headerBg) !important;
  font-size: 18px;
  font-weight: var(--fontWeight400);
  text-decoration: none;
  cursor: pointer;
  padding: 0 20px;
}

.menuButton {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.logo-container {
  width: 20%;
  height: 100%;
  display: flex;
  gap: 10px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.btn {
  display: none;
  font-size: 24px;
  color: var(--headerBg) !important;
  background-color: transparent !important;
  border: none !important;
  padding: 2px;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 768px) {
  .navLinks a {
    color: var(--headerBg) !important;
    font-size: 12px;
    font-weight: var(--fontWeight400);
    text-decoration: none;
    cursor: pointer;
    padding: 0 10px;
  }

  .navLinks {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 5px;
  }

  .list {
    display: flex;
    list-style: none !important;
    padding: 0;
  }

  .navLinks.open {
    display: flex;
    width: 100%;
  }

  .btn {
    display: block;
  }

  .logo-container {
    width: 90%;
    height: 100%;
    display: flex;
    gap: 10px;
  }
}
