.blog {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.blog-container {
  width: 90%;
  padding: 1.5rem;
}

.header-div {
  width: 100%;
  background: linear-gradient(135deg, #0b2b3e 0%, #0493a5 100%);
  border-radius: 15px;
  text-align: center;
  padding: 3rem;
}

.heading-text {
  color: #ffffff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 25px;
  max-width: 1300px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .blog-container {
    width: 100%;
    padding: 0.5rem 0;
  }

  .header-div {
    padding: 0.75rem;
  }

  .heading-text {
    font-size: 1rem;
  }

  .blog-grid {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }
}
