@import url(../../styles/colors.css);
@import url(../../styles/sizes.css);

.footer-container {
  width: 90%;
  display: flex;
  padding: 1rem;
}

.logo {
  width: 350px;
  object-fit: fill;
}

.footer-text {
  color: #515151;
  font-size: var(--md);
  font-weight: var(--fontWeight400);
  margin-bottom: 0.25rem;
}

.footer-tittle-text {
  color: #515151;
  font-size: var(--lg);
  font-weight: var(--fontWeight500);
  margin-bottom: 1.5rem !important;
}

.footer-address-text {
  color: #515151;
  font-size: var(--sm);
  font-weight: var(--fontWeight400);
  margin-bottom: 0 !important;
}

.footer-no-text {
  color: #515151;
  font-size: var(--sm);
  font-weight: var(--fontWeight400);
  margin-bottom: 0 !important;
}

.follow-us-text {
  color: #515151;
  font-size: var(--xs);
  font-weight: var(--fontWeight400);
  margin-bottom: 0.25rem;
}

.footer-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5vw;
  color: #515151;
}

.footer-icon-two {
  width: 20px;
  height: 20px;
  margin-right: 0.5vw;
  margin-left: 0.5vw;
  color: #515151;
}

.footer-content {
  width: 50%;
  border-right: 2px solid #515151;
  margin-right: 1rem;
}

.footer-contact {
  width: 50%;
  margin: auto 0;
}

@media screen and (max-width: 768px) {
  .footer-container {
    flex-wrap: wrap;
    display: flex;
    padding: 0;
  }

  .footer-content {
    width: 100%;
    border-right: none;
    margin-right: 0;
    border-bottom: 1px solid #515151;
    padding-bottom: 1rem;
  }

  .footer-contact {
    width: 100%;
    margin: 0;
  }

  .logo {
    width: 100%;
    object-fit: cover;
  }

  .footer-text {
    font-size: var(--xs);
  }

  .footer-tittle-text {
    font-size: var(--md);
    margin-top: 1rem;
  }

  .footer-address-text {
    font-size: var(--xs);
  }

  .footer-no-text {
    font-size: var(--xs);
  }

  .footer-icon {
    width: 15px;
    height: 15px;
    margin-right: 0.9vw;
  }

  .footer-icon-two {
    width: 15px;
    height: 15px;
    margin-right: 0.9vw;
    margin-left: 0.9vw;
  }
}
