.blogCard {
  width: 100%;
  max-width: 800px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.blogImage {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.blogContent {
  padding: 20px;
}

.blogTitle {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.blogMeta {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.author {
  font-weight: bold;
  color: #0493a5;
}

.blogText {
  font-size: 16px;
  color: #444;
  line-height: 1.6;
}

.btnClose {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 10px;
  padding: 0;
  color: #ccc;
}

.closeIcon {
  width: 10px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .blogCard {
    max-width: 95%;
  }

  .blogTitle {
    font-size: 20px;
  }

  .blogText {
    font-size: 14px;
  }
}
