.our-mission-and-vision {
  width: 100%;
  padding: 50px 20px;
  background: linear-gradient(135deg, #0b2b3e 0%, #0493a5 100%);
  border-radius: 15px;
}

.our-mission-and-vision-container {
  width: 100%;
  padding: 1rem;
  height: auto;
}

.our-mission-tittle {
  padding-bottom: 2.5rem;
  text-align: center;
}

.heading-text {
  color: #ffffff;
  font-size: 2.5rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

/*Card */
.card {
  width: 30%;
  padding: 2rem;
  height: 400px;
  background: var(--headerBg3); /* Glass effect */
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: auto;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.2);
}

.card-text {
  font-size: 18px;
  color: var(--headerBg2);
  text-align: center;
}

.card-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--headerBg2);
  text-align: center;
}

.circle {
  width: fit-content;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--headerBg);
  margin-bottom: 1.5rem;
}

.card-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .our-mission-and-vision {
    padding: 20px 5px;
  }

  .our-mission-and-vision-container {
    padding: 0.5rem;
  }

  .heading-text {
    font-size: 1.375rem;
  }

  .card-container {
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .card {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    height: 300px;
  }

  .card-text {
    font-size: 12px;
  }

  .card-title {
    font-size: 15px;
  }
}
