.blog-card {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: #fff;
}

.blog-card:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
}

.blog-image {
  width: 100%;
  height: 260px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.blog-content {
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  text-align: justify;
  height: 200px;
}

.blog-text {
  flex-grow: 1; /* Allows text content to take available space */
}

.blog-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.blog-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
}

.terms-text {
  font-size: 14px;
  color: #888;
  font-style: italic;
  margin-bottom: 15px;
}

.blog-button {
  background-color: #000;
  color: #fff;
  padding: 0.7rem 1.2rem;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: auto;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.blog-button:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

@media screen and (max-width: 768px) {
  .blog-image {
    height: auto;
  }

  .blog-content {
    padding: 0.75rem;
  }

  .blog-title {
    font-size: 14px;
  }

  .blog-description {
    font-size: 12px;
  }

  .blog-button {
    padding: 0.5rem 0.7rem;
    font-size: 12px;
  }
}
