.why-choose-us {
  width: 100%;
  padding: 50px 20px;
  background: linear-gradient(135deg, #0b2b3e 0%, #0493a5 100%);
  border-radius: 15px;
}

.why-choose-us-container {
  width: 100%;
  padding: 1rem;
  height: auto;
}

.why-choose-us-title {
  padding-bottom: 2.5rem;
  text-align: center;
}

.heading-text {
  color: #ffffff;
  font-size: 2.5rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.card {
  width: 48%;
  height: 455px;
  background-color: var(--headerBg3);
  backdrop-filter: blur(10px);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.2);
}
/* Image Styling */
.image-container {
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Content Styling */
.card-content {
  padding: 15px;
  text-align: center;
}

.card-title {
  font-size: 22px;
  font-weight: 600;
  color: var(--headerBg2);
  margin-bottom: 10px;
}

.card-text {
  font-size: 16px;
  color: var(--headerBg2);
  line-height: 1.6;
  text-align: center;
  margin-bottom: 10px;
}

/* List Style */
.card-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-item {
  font-size: 16px;
  color: var(--headerBg2);
  padding: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.list-item:last-child {
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .why-choose-us {
    padding: 20px 5px;
  }

  .why-choose-us-container {
    padding: 0.5rem;
  }

  .heading-text {
    font-size: 1.375rem;
  }

  .card {
    width: 100%;
    height: auto;
  }

  .image-container {
    width: 100%;
    height: 10rem;
  }

  .card-title {
    font-size: 16px;
  }

  .card-text {
    font-size: 12px;
  }

  .list-item {
    font-size: 12px;
  }
}
