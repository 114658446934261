/* Section Layout */
.home-section {
  display: flex;
  justify-content: space-between;
  padding: 50px 50px;
  background-color: #f9f9f9;
}

/* Left Side - Text Content */
.home-content {
  flex: 1;
  padding-right: 40px;
}

.home-title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.home-text {
  font-size: 1.25rem;
  font-weight: lighter;
  color: #555;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* Right Side - Image Cards */
.home-images {
  flex: 1;
  width: 450px;
  height: 450px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border-radius: 6px;
}

.home-images:hover {
  transform: scale(1.05);
}

.home-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .home-section {
    flex-direction: column;
    text-align: center;
    padding: 0;
  }

  .home-content {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .home-images {
    width: 100%;
  }

  .home-title {
    font-size: 1rem;
  }

  .home-text {
    font-size: 0.9rem;
  }

  .home-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}
