.home {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.image-container {
  width: 100%;
  position: relative;
}

.space-img {
  width: 100vw;
  height: 70vh;
  object-fit: cover;
}

.home-container {
  width: 90%;
  padding: 1.5rem;
}

.amenities-container {
  width: 100%;
  padding: 1.5rem;
  background-color: black;
}

.header-div {
  position: absolute;
  width: 100%;
  top: 20%;
}

.text-div {
  width: 80%;
  height: fit-content;
  margin: 0 auto;
  text-align: center;
}

.btn {
  background: linear-gradient(135deg, #0b2b3e 0%, #0493a5 100%);
  color: #ffffff;
  padding: 0.5rem 1.5rem;
  border-radius: 100px;
  margin: 1rem;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.btn:hover {
  transform: translateY(5%);
  background: linear-gradient(135deg, #0493a5 0%, #0b2b3e 100%);
  color: #c4b0b0;
}

.heading-text {
  color: #ffffff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.header-text {
  color: var(--headerBg2);
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 0.5rem;
}

.modal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  display: none;
}

.google-ads {
  box-shadow: none !important;
  border: none !important;
}

@media screen and (max-width: 768px) {
  .header-text {
    font-size: 1.2rem;
    padding: 0;
  }

  .home-container {
    padding: 0.75rem;
  }

  .amenities-container {
    padding: 0.75rem;
  }
}
