:root {
  --lmPrimary: #05447a;
  --lmPrimaryDark: #002333;
  --lmPrimarylight: #3a85bf;
  --lmSecondary: #ebf0f5;
  --labelText: #434356;
  --textField: #696978;
  --helperText: #777777;
  --defaultTextFieldBorder: #d2d2d7;
  --activeTextFieldBorder: #376995;
  --disabledTextFieldBorder: #dfdfe2;
  --disabledTextFieldBoxBg: #f2f2f2;
  --disabledCheckbox: #bbbbc2;
  --dropdownBorder: #d8e7f2;
  --tertiaryAction: #777777;
  --inactiveButton: #7d9db8;
  --defaultIcon: #667b85;
  --activeIcon: #ffffff;
  --inactiveSlider: #c4c4c4;
  --filterTextFieldActive: #33496b;
  --primaryButtonHover: #043866;
  --secondaryButtonHover: #e1e6eb;
  --outlinedButtonHover: #f5f8fa;
  --tertiaryButtonHover: #f5f5f5;
  --tableHeaderBg: #f5f6fa;
  --tableBorder: #ececee;
  --auditActive: #223a60;
  --auditBoxBg: #f7fafc;
  --auditBoxBgDark: #cfd7e0;
  --success: #07b871;
  --success2: #2a9d8f;
  --warning: #f49025;
  --reject: #f20c33;
  --reject2: #d6564d;
  --white: #ffffff;
  --black: #000000;
  --spaceBorder: #9bb4ca;
  --scrollBackground: #ebf2f8;
  --badgeLayoutBg: #77777714;
  --timesIcon: #d6564d;
  --lmPrimary2: #004666;
  --lmPrimary3: #002333;
  --calllogBgLight: #f9f9f9;
  --barColor: #d9d9d9;
  --tableBg: #fafcfd;
  --lmGrey: #223a6014;
  --lineBg1: #9bb4ca;
  --lineBg2: #09bbca;
  --selectBox: #43435666;
  --textColor: #434356cc;
  --badgeBg1: #00233314;
  --badgebg2: #223a60;
  --delIcon: #d6564d;
  --DropdownBg: #193947;
  --headerBg: #0493a5;
  --headerBg2: #0b2b3e;
  --headerBg3: #e1ffff;
  --headerBg4: #f8ffff;
}
